@import '@assets/styles/abstracts';

.NewsletterInput {
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  input {
    display: block;
    box-sizing: border-box;
    border: none;

    font-size: $font-size-md;

    padding: 15px 5px 15px 15px;
    width: 100%;
    min-width: fit-content;

    &::placeholder {
      font-size: $font-size-sm;
      font-style: italic;
      font-weight: $font-weight-light;
    }
  }

  &.primary {
    input {
      background-color: color(primary, lightest);
      color: color(primary);

      &::placeholder {
        color: color(primary, light);
      }
    }
  }

  &.secondary {
    border-radius: 0;
    input {
      background-color: color(primary, lighter);
      color: color(white);

      &::placeholder {
        color: color(white);
      }
    }
  }

  .Error {
    margin-top: 5px;
    margin-left: 15px;
    color: color(primary);
  }
}
