@import '@assets/styles/abstracts';

.SecondaryFooter {
  display: flex;
  background-color: color(secondary, dark);

  padding: px(3) px(5);

  .Description {
    color: color(secondary, lightest);
    margin: 0 auto;

    font-size: $font-size-xs;
    font-weight: $font-weight-light;
  }
}
