@import '@assets/styles/abstracts';

.SearchInput {
  position: relative;
  overflow: hidden;
  width: 100%;

  input {
    display: block;
    box-sizing: border-box;

    font-size: $font-size-md;

    padding: 15px 20px 15px 50px;
    width: 100%;

    &::placeholder {
      text-transform: uppercase;
      font-size: $font-size-xs;

      opacity: 1;
    }
  }

  &.default {
    color: color(white);

    input {
      border-radius: 3px;
      border: none;

      background-color: color(secondary, dark);
      color: color(white);

      &::placeholder {
        color: color(white);
      }
    }
  }

  &.transparent {
    color: color(white);

    input {
      border-radius: 3px;
      border: 1px solid color(white);
      background: transparent;

      color: color(white);

      &::placeholder {
        color: color(white);
        opacity: 1;
      }
    }
  }

  &.basic {
    color: color(support, light);

    input {
      border: none;
      border-bottom: 2px solid color(support, light);

      background: transparent;

      color: color(support, darkest);

      &::placeholder {
        color: color(support, light);
        opacity: 1;
      }
    }
  }

  .Icon {
    position: absolute;
    top: 15px;
    left: 20px;
    transform: scaleX(-1);

    font-size: 18px;

    pointer-events: none;
  }
}
