@import '@assets/styles/abstracts';

.Menu {
  display: flex;
  flex-direction: row;

  margin: 0;

  .Account {
    padding: px(3) px(1.5) !important;

    font-size: $font-size-xl !important;
  }

  .SearchWrapper {
    padding: px(2) px(1.5) !important;
  }

  .DestSubMenu,
  .AccountSubMenu {
    z-index: -1;
  }
}
