@import '@assets/styles/abstracts';

.MobileMenu {
  position: fixed;
  top: 0;
  left: 110%;
  right: -110%;
  bottom: 0;

  background-color: color(white);

  overflow: hidden;

  @include transition(all, xl);

  &.Open {
    left: 0;
    right: 0;
  }

  .Inner {
    display: flex;
    flex-direction: column;

    padding-top: px(2);

    overflow: scroll;

    width: 100%;
    height: 100%;

    .MobileMenuItem {
      margin-bottom: px(2);
    }

    .AccountIcon {
      margin-right: px(1);
    }

    .SearchWrapper {
      padding: 0;
    }
  }
}
