@import '@assets/styles/abstracts';

.PrimaryFooter {
  background-color: color(secondary);

  .Inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: px(8) 0;

    .Lists {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      width: 50%;
    }

    .Sharing {
      display: flex;
      justify-content: center;
      flex: 1;
    }

    @include media-breakpoint-down(sm) {
      .Lists {
        width: 66%;
      }
    }

    @include media-breakpoint-down(xs) {
      .Lists {
        width: 100%;

        .List {
          width: 100%;
          margin: 0 px(5);
          margin-bottom: px(8);
          align-content: center;
          justify-content: center;
        }
      }

      .Sharing {
        margin: 0 px(5);
        justify-content: flex-start;
      }
    }
  }
}
