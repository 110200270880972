@import '@assets/styles/abstracts';

.Form {
  .Email {
    margin-bottom: px(2);
  }

  .Error {
    font-weight: $font-weight-semibold;
    font-size: $font-size-xs;

    opacity: 0;

    margin-top: px(2);

    min-height: #{$line-height-base}rem;

    @include transition(opacity, sm);

    &.Show {
      opacity: 1;
    }
  }

  .Checkbox {
    font-size: $font-size-xs;
    color: color(support, darkest);

    margin-bottom: px(1);
  }

  .Button {
    margin-top: px(2);
    width: 100%;
  }

  &.Secondary {
    .Email {
      margin-bottom: px(2);
    }

    .Error {
      margin: 0;
      color: color(white);
    }

    .Checkbox {
      color: color(white);

      @include max-lines(2);

      overflow: visible;
    }

    .Button {
      margin: 0;
    }
  }
}
