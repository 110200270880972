@import '@assets/styles/abstracts';

.SearchOverlay {
  position: fixed;
  background-color: color(primary);

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;

  opacity: 0;

  z-index: calc(#{$navbar-z-index} + 1);

  transform: translateY(-100px);
  cursor: auto;

  @include transition(all, lg);

  @include media-breakpoint-down(md) {
    @include transition(all, md);
  }

  &.IsOpen {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }

  .Container {
    position: relative;
    height: 100%;

    .InputWrapper {
      position: absolute;

      display: flex;
      flex-direction: row;
      align-items: center;

      top: 50%;

      left: 15px;
      right: 15px;

      transform: translateY(-50%);

      padding-bottom: px(2);

      border-bottom: 1px solid color(white);

      .Input {
        width: 100%;

        border: none;
        background: none;

        color: color(white);

        font-size: $font-size-h1;

        @include media-breakpoint-down(lg) {
          font-size: $font-size-h1 * 0.9;
        }

        @include media-breakpoint-down(md) {
          font-size: $font-size-h1 * 0.8;
        }

        @include media-breakpoint-down(sm) {
          font-size: $font-size-h1 * 0.7;
        }

        @include media-breakpoint-down(xs) {
          font-size: $font-size-h1 * 0.6;
        }

        &::placeholder {
          font-size: $font-size-h1;
          font-weight: $font-weight-light;

          text-transform: capitalize;
          color: color(white);
          opacity: 1;

          @include media-breakpoint-down(lg) {
            font-size: $font-size-h1 * 0.9;
          }

          @include media-breakpoint-down(md) {
            font-size: $font-size-h1 * 0.8;
          }

          @include media-breakpoint-down(sm) {
            font-size: $font-size-h1 * 0.7;
          }

          @include media-breakpoint-down(xs) {
            font-size: $font-size-h1 * 0.6;
          }
        }

        &:focus {
          outline: none;
        }
      }

      .IconWrapper {
        display: flex;
        align-items: center;

        height: 50px;

        padding: 0 0 0 px(4);

        .Icon {
          transform: scaleX(-1);

          color: color(white);
          font-size: $font-size-lg;

          pointer-events: none;
        }
      }
    }

    .Close {
      position: absolute;
      right: 0;
      top: 50px;
      padding: 10px 15px;

      cursor: pointer;

      .CloseIcon {
        color: color(white);
        font-size: 38px;
      }
    }
  }
}
