@import '@assets/styles/abstracts';

.LargeSubMenu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  opacity: 0;
  pointer-events: none;

  @include transition(all, md);

  &:before {
    display: none;
  }

  &.Open {
    top: 100%;
    opacity: 1;

    pointer-events: all;
  }

  .Inner {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    row-gap: px(8);
    column-gap: px(1);

    padding: px(4) px(2) px(6);
    text-transform: none;
    letter-spacing: normal;

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1, 1fr);
    }

    .SubMenuList {
      z-index: 2;
    }
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;

    z-index: -2;

    &.Open {
      pointer-events: all;
    }
  }
}
