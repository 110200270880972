@import '@assets/styles/abstracts';

.Card {
  display: block;
  position: relative;
  overflow: hidden;

  &.shadow-sm {
    @include box-shadow(sm);
  }

  &.shadow-md {
    @include box-shadow(md);
  }

  &.WhiteBackground {
    background-color: color(white);
  }

  .ImageWrapper {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: -1;

    .Background {
      width: 100% !important;
      height: 100% !important;
      min-width: 1024px !important;
      min-height: 512px !important;

      background-color: color(white);
    }
  }

  &.AltBackground {
    .ImageWrapper {
      .Background {
        min-width: 0 !important;
        min-height: 0 !important;
        background-color: #fdf9f8;
      }
    }
  }

  &.Clickable {
    @include transition(box-shadow, sm);

    &:hover {
      @include box-shadow(md);
    }
  }
}
