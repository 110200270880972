@import '@assets/styles/abstracts';

.LargeSubMenuList {
  line-height: $line-height-base;

  .Title {
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    border: none !important;

    color: color(primary) !important;

    &:hover {
      color: color(primary, light) !important;
    }
  }

  .Content {
    margin-top: px(0.5);
    margin-bottom: px(3);

    @include max-lines(5);

    .ListItem {
      padding: 0;

      > a {
        font-weight: $font-weight-semibold;

        border: none;
      }

      &::before {
        display: none;
      }
    }
  }

  .OverviewLink {
    font-size: $font-size-xs;
  }

  @include media-breakpoint-down(md) {
    .Title {
      font-size: $font-size-md;
    }

    .Content {
      margin-bottom: px(1);

      .ListItem {
        > a {
          font-size: $font-size-sm;
          font-weight: $font-weight-normal;
        }
      }
    }

    .OverviewLink {
      margin-top: 0;
      font-size: $font-size-xxs !important;
    }
  }
}
