@import '@assets/styles/abstracts';

.MobileSubMenu {
  display: flex;
  flex-direction: column;

  opacity: 0;
  padding: 0 px(2);
  margin-top: 0;

  pointer-events: none;
  text-transform: none;
  letter-spacing: normal;

  transition: opacity 100ms, margin-top 250ms, height 250ms;

  &.Open {
    opacity: 1;
    margin-top: px(2);

    pointer-events: all;
  }

  .Inner {
    margin: 0;

    .ItemWrapper {
      padding: 0;
      margin: 0;

      &:before {
        display: none;
      }

      .SubMenuItem {
        display: block;

        color: color(support, darkest);

        font-size: $font-size-xs;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        letter-spacing: 1px;
        line-height: 1;

        padding: px(1) 0;

        &:hover {
          color: color(primary);
        }
      }
    }
  }
}
