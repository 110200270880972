@import '@assets/styles/abstracts';

.Wrapper {
  position: inherit;
  padding: 0;
  margin: 0;

  &:before {
    display: none;
  }

  .MobileMenuItem {
    display: flex;
    flex-direction: column;
    padding: px(2);

    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    text-transform: uppercase;

    letter-spacing: 1px;
    line-height: 1;

    &::after {
      content: ' ';

      position: absolute;

      bottom: 0;
      left: 0;

      height: 2px;
      width: 0;

      background-color: color(primary);

      @include transition(all, sm);
    }

    &.selected {
      &::after {
        width: 100%;
      }
    }

    .Arrow {
      position: absolute;
      right: px(2);

      @include transition(transform, md);

      &.Open {
        transform: rotate(180deg);
      }
    }
  }
}
