@import '@assets/styles/abstracts';

.SearchButton {
  display: inline-flex;
  flex-direction: row;

  background-color: color(secondary, dark);

  border-radius: 3px;
  padding: 12px 12px;

  letter-spacing: 1px;
  text-transform: uppercase;

  cursor: pointer;

  .Icon {
    color: color(white);
    transform: scaleX(-1);

    font-size: 18px;

    pointer-events: none;
  }

  .Label {
    font-size: $font-size-xs;
    font-weight: $font-weight-medium;
    line-height: $line-height-base;

    color: color(white);
    margin: 0;
  }
}
