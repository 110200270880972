@import '@assets/styles/abstracts';

.TextLink {
  @include transition(color, sm);
  font-weight: $font-weight-bold;

  cursor: pointer;
  display: inline;

  &.primary {
    color: color(support, darkest);
    text-decoration: none;

    border-bottom: 1px solid color(primary);

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  &.secondary {
    color: color(white);
    text-decoration: none;
  }

  &.tertiary {
    color: color(secondary);
    text-decoration: none;
  }

  &.basic {
    font-weight: normal;
    color: color(black);
    text-decoration: underline;
  }

  &.basic-white {
    font-weight: normal;
    color: color(white);
    text-decoration: underline;

    &:hover {
      color: color(secondary);
    }
  }

  &:hover {
    color: color(primary);
  }
}
