@import '@assets/styles/abstracts';

.HeaderNewsletterSignup {
  position: fixed;
  display: flex;
  justify-content: center;
  background-color: color(primary);

  width: 300px;
  padding: px(2) px(2) px(2) px(6.5);

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  top: calc(20% + #{$navbar-height});
  left: calc(100% - #{px(6.5)});

  transform: translate(0, calc(-50% + #{$navbar-height}));

  z-index: calc(#{$navbar-z-index} - 1);

  @include transition(all, lg);

  @include media-breakpoint-down($navbar-mobile-breakpoint) {
    top: calc(50%);
    left: 100%;
    transform: translateY(-50%);

    border-radius: 0;
  }

  &.Open {
    padding-left: px(4);
    transform: translate(-100%, calc(-50% + #{$navbar-height}));
    left: 100%;

    .Icon {
      left: 100%;
      transform: translateX(-100%);
    }

    .ClosedLabel {
      opacity: 0;
    }

    .ClickableBar {
      width: px(4);
    }

    @include media-breakpoint-down($navbar-mobile-breakpoint) {
      transform: translate(-100%, -50%);
      width: 100%;

      @include box-shadow(lg);
    }
  }

  .ClickableBar {
    position: absolute;
    top: 0;
    left: 0;
    width: px(6);
    height: 100%;
    z-index: 2;
  }

  .Icon {
    display: inline;
    position: absolute;

    padding: px(2);
    line-height: 1;

    top: 0;
    left: px(1);

    color: color(white);

    font-size: $font-size-lg;

    z-index: 2;

    @include transition(all, lg);

    @include media-breakpoint-down($navbar-mobile-breakpoint) {
      display: none;
    }
  }

  .MobileIcon {
    display: none;
    position: absolute;

    padding: px(2);
    line-height: 1;

    top: 25px;
    right: 100%;

    color: color(white);

    font-size: $font-size-lg;

    background-color: color(primary);

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @include media-breakpoint-down($navbar-mobile-breakpoint) {
      // display: inline;
    }
  }

  .MobileCloseIcon {
    display: none;
    position: absolute;

    padding: px(2);
    margin: -#{px(2)};
    line-height: 1;

    top: 0;
    right: 0;

    color: color(white);

    font-size: $font-size-lg;

    @include transition(all, lg);

    @include media-breakpoint-down($navbar-mobile-breakpoint) {
      display: inline;
    }
  }

  .ClosedLabel {
    position: absolute;

    left: calc(#{px(3)} + (#{$font-size-xs} / 2));
    bottom: px(4);

    transform: translate(-50%, -100%) rotate(-90deg);

    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    color: color(white);

    opacity: 1;

    @include transition(opacity, lg);

    @include media-breakpoint-down($navbar-mobile-breakpoint) {
      display: none;
    }
  }

  .Content {
    position: relative;

    .Title {
      font-size: $font-size-md;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;

      color: color(white);

      z-index: 1;
    }

    .Description {
      font-size: 13px;
      font-weight: $font-weight-medium;

      color: color(white);

      margin-bottom: px(1);
    }

    @include media-breakpoint-down($navbar-mobile-breakpoint) {
      margin: auto 0;
    }
  }
}
