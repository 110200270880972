@import '@assets/styles/abstracts';

.CheckboxInput {
  display: flex;
  width: fit-content;

  font-size: $font-size-sm;
  user-select: none;

  cursor: pointer;

  .Checkbox {
    width: 0;
    height: 0;
    position: relative;
    visibility: hidden;

    cursor: pointer;

    &.Checked ~ .Label {
      .Icon {
        opacity: 1;
      }
    }
  }

  .Label {
    position: relative;

    flex: 1;
    padding-left: px(4);

    line-height: 1.2;

    cursor: pointer;

    &:before {
      display: block;
      content: '';

      position: absolute;
      visibility: visible;

      top: 0;
      left: 0;

      width: 16px;
      height: 16px;

      background-color: #ffffff;

      @include box-shadow(sm);
    }

    .Icon {
      position: absolute;
      visibility: visible;
      opacity: 0;

      font-size: 24px;

      left: -3px;
      top: -5px;

      max-height: none;

      color: color(support, darkest);

      @include transition(opacity, sm);
    }
  }
}
