@import '@assets/styles/abstracts';

.Wrapper {
  position: absolute;
  top: 0;
  right: 0;

  opacity: 0;
  pointer-events: none;

  z-index: -1;

  @include transition(all, md);

  &:before {
    display: none;
  }

  &.Open {
    top: 100%;
    opacity: 1;

    pointer-events: all;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;

    z-index: -2;

    &.Open {
      pointer-events: all;
    }
  }
}

.SubMenu {
  display: flex;
  flex-direction: column;
  width: fit-content;

  letter-spacing: normal;
  text-transform: none;

  min-width: 250px;

  padding: px(4);

  .Inner {
    margin: 0;

    .ItemWrapper {
      padding: 0;
      margin: 0;

      z-index: 2;

      &:before {
        display: none;
      }

      .SubMenuItem {
        display: block;

        color: color(support, darkest);

        font-size: $font-size-lg;
        font-weight: $font-weight-semibold;
        line-height: 1;

        margin-bottom: 6px;

        &:hover {
          color: color(primary);
        }
      }
    }
  }
}
