@import '@assets/styles/abstracts';

.Wrapper {
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:before {
    display: none;
  }

  .Link {
    display: flex;
    height: 100%;

    color: color(white) !important;

    @include transition(color, sm);

    &:hover {
      color: color(primary) !important;
    }

    .MenuItem {
      display: flex;
      padding: px(4) px(1.5);
      flex: 1;

      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 1;

      margin: auto 0;

      &::after {
        content: ' ';

        position: absolute;

        bottom: 0;
        left: 0;

        height: 2px;
        width: 0;

        background-color: color(primary);

        @include transition(all, sm);
      }

      &.selected {
        &::after {
          width: 100%;
        }
      }

      @include media-breakpoint-down(md) {
        font-size: $font-size-xxs;
        padding: px(4) px(1);
      }
    }
  }
}
