@import '@assets/styles/abstracts';

.Content {
  margin-top: $navbar-height;
  padding-bottom: $layout-vertical-padding;

  @include media-breakpoint-down($navbar-mobile-breakpoint) {
    margin-top: $navbar-mobile-height;
  }

  &.Paddings {
    padding: $layout-vertical-padding $layout-horizontal-padding;
  }
}
