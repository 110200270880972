@import '@assets/styles/abstracts';

.FooterSharing {
  .Title {
    font-weight: $font-weight-semibold;
    color: color(white);

    margin-bottom: px(2);
  }

  .Platforms {
    .Platform {
      font-size: $font-size-h4;
      margin-right: px(2.5);
      border-bottom: none;

      .Icon {
        @include transition(color, xs);
      }
    }
  }
}
