@import '@assets/styles/abstracts';

.Button {
  display: flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;

  font-weight: $font-weight-medium;
  background-color: #ddd;

  cursor: pointer;

  @include transition(opacity, md);

  &.sm {
    padding: px(1.5) px(3);
    font-size: $font-size-xxs;
    font-weight: $font-weight-semibold;

    .Icon {
      font-size: $font-size-md;
    }
  }

  &.md {
    padding: px(1.5) px(3);
  }

  &.lg {
    padding: px(3) px(4);
  }

  &:hover {
    opacity: 0.66;
  }

  &.primary {
    background-color: color(primary);
    color: color(white);
  }

  &.primary-inverted {
    background-color: color(white);
    color: color(primary);
  }

  &.secondary {
    background-color: color(secondary);
    color: color(white);
  }

  &.secondary-inverted {
    background-color: color(white);
    color: color(secondary);
  }

  .Icon {
    height: 100%;
    font-size: 20px;

    &Left {
      margin-right: px(2);
    }

    &Right {
      margin-left: px(2);
    }
  }

  .ContentWrapper {
    display: flex;
    opacity: 1;

    @include transition(opacity, sm);
    &.Loading {
      opacity: 0;
    }
  }

  .Loader {
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include transition(opacity, sm);

    &.Loading {
      opacity: 1;
    }
  }
}
