@import '@assets/styles/abstracts';

.Loader,
.Loader:after {
  width: 10em;
  height: 10em;
  border-radius: 50%;
}

.Loader {
  position: relative;
  border-top: 1.1em solid lighten(color(primary), 50%);
  border-right: 1.1em solid lighten(color(primary), 50%);
  border-bottom: 1.1em solid lighten(color(primary), 50%);
  border-left: 1.1em solid color(primary);
  margin: 60px auto;
  animation: load8 1.1s infinite linear;
  font-size: 10px;
  text-indent: -9999em;
  transform: translateZ(0);

  &--white {
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid rgb(255, 255, 255);
  }

  &--secondary {
    border-top: 1.1em solid lighten(color(secondary), 50%);
    border-right: 1.1em solid lighten(color(secondary), 50%);
    border-bottom: 1.1em solid lighten(color(secondary), 50%);
    border-left: 1.1em solid color(secondary);
  }
}

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
