@import '@assets/styles/abstracts';

.FooterList {
  .Title {
    font-weight: $font-weight-semibold;
    color: white;
  }

  .Items {
    display: flex;
    flex-direction: column;

    .ListItem {
      font-weight: $font-weight-semibold;
      margin-bottom: px(1);
    }
  }
}
