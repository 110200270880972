@import '@assets/styles/abstracts';

.Link {
  border: none;
  text-decoration: none;
  color: color(support, darkest);

  &.HasLink {
    cursor: pointer;
  }
}
